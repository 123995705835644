body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #2c2c2c; /* Fundo cinza escuro */
  color: #ffffff; /* Letras brancas */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Garante que o body ocupe a altura total da viewport */
}

header {
  background-color: #4CAF50;
  padding: 10px 20px;
  color: white;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

.container {
  padding: 20px;
  text-align: center; /* Centraliza o texto dentro da .container */
}

button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

input[type="text"], input[type="number"], input[type="file"] {
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
}

input[type="checkbox"] {
  margin-right: 10px;
}

.event-image {
  height: 300px; /* Aumente a altura da imagem conforme necessário */
  width: auto;
  margin-bottom: 20px;
}
