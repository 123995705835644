html, body {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Arial', sans-serif;
  /* background-color: #f4f4f4; */
}

.confirmation-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Alinha o conteúdo verticalmente */
  align-items: center; /* Alinha o conteúdo horizontalmente */
  text-align: center;
  width: 100%; /* Ocupa toda a largura no celular */
  max-width: 600px; /* Aumenta a largura em telas maiores */
  margin: 0 auto;
  padding: 20px;
  color: #000000;
  background-color: rgb(255, 255, 255);
  border-radius: 10px; /* Adiciona bordas arredondadas */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Adiciona uma leve sombra */
  height: auto; /* Altura automática */
}

.confirmation-title {
  font-size: 2em;
  color: #1c7bb9;
  margin-bottom: 20px;
}

.confirmation-success {
  margin-top: 40px;
}

.confirmation-success svg {
  display: block;
  margin: 0 auto 20px;
}

.confirmation-subtitle {
  font-size: 1.2em;
  margin: 10px 0;
}

.confirmation-deadline {
  font-size: 0.9em;
  color: #000000;
  margin-bottom: 20px;
}

.confirmation-qr-container {
  margin-bottom: 20px;
}

.confirmation-qr {
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.confirmation-code {
  margin-bottom: 20px;
  width: 80%;
}

.confirmation-code-input {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  text-align: center;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.confirmation-instructions {
  margin-top: 20px;
  text-align: left;
}

.confirmation-instructions p {
  font-weight: bold;
  margin-bottom: 10px;
}

.confirmation-instructions ol {
  padding-left: 20px;
}

.confirmation-instructions ol li {
  margin-bottom: 10px;
}

/* Media query para dispositivos móveis */
@media (max-width: 600px) {
  .confirmation-container {
    padding: 10px;
    width: 100%;
  }
}
